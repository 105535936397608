<template>
  <div class="cursor">
    <div class="cursor__ball cursor__ball--big">
      <svg height="30" width="30">
        <circle cx="15" cy="15" r="12" stroke-width="0"/>
      </svg>
    </div>

    <div class="cursor__ball cursor__ball--small">
      <svg height="10" width="10">
        <circle cx="5" cy="5" r="4" stroke-width="0"/>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import {TweenMax} from 'gsap';
import {defineComponent, onMounted} from 'vue';

export default defineComponent({
  name: 'VCursor',
  /**
   *
   */
  setup() {

    onMounted(() => {
      // Move the cursor
      const bigBall = document.querySelector('.cursor__ball--big');
      const smallBall = document.querySelector('.cursor__ball--small');
      const hoverables = document.querySelectorAll('.hoverable');

      document.body.addEventListener('mousemove', (e: MouseEvent) => {
        TweenMax.to(bigBall, 0.4, {
          x: e.pageX - 15,
          y: e.pageY - 15,
        });
        TweenMax.to(smallBall, 0.1, {
          x: e.pageX - 7,
          y: e.pageY - 7,
        });
      });

      for (const element of hoverables) {
        element.addEventListener('mouseenter', () => {
          TweenMax.to(bigBall, 0.3, {
            scale: 4,
          });
        });
        element.addEventListener('mouseleave', () => {
          TweenMax.to(bigBall, 0.3, {
            scale: 1,
          });
        });
      }
    });
  },
});
</script>

<style lang="scss" scoped>
@import '~@/scss/global.scss';

.cursor {
  pointer-events: none;

  @include respondTo(mobile) {
    display: none;
  }

  &__ball {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;

    circle {
      fill: #f7f8fa;
    }

    &--small {
      top: -4px;
      left: 2px;
    }
  }
}
</style>
