<template>
  <div class="alert">
    <div v-if="isSuccess" class="alert--success">
      <font-awesome-icon
        size="6x"
        :icon="['far', 'check-circle']"
      ></font-awesome-icon>

      <strong>
        {{ message }}ありがとうございます。
        <br />
        折り返しのご連絡までしばらくお待ちください。
      </strong>
    </div>
    <div v-else class="alert--failed">
      <font-awesome-icon
        size="6x"
        :icon="['far', 'times-circle']"
      ></font-awesome-icon>
      <strong>
        サーバーの送信が失敗しました。しばらく立ってからやり直してくだだい。
      </strong>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'VResponseMessage',
  props: {
    status: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isSuccess = ref(false);

    onMounted(() => {
      isSuccess.value = props.status === 'success';
    });
    return { isSuccess };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/scss/global.scss';

.alert {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--success {
    font-size: 1.6em;
    margin-top: 10px;

    svg {
      margin: 30px auto;
      display: block;
      color: theme('success');
    }
  }

  &--failed {
    font-size: 1.6em;
    margin-top: 10px;

    svg {
      margin: 30px auto;
      display: block;
      color: theme('error');
    }
  }
}
</style>
