<template>
  <div class="footer">
    <small>Copyright © Sofa hair All Rights Reserved.</small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppFooter',
});
</script>

<style lang="scss" scoped>
@import '~@/scss/global';

.footer {
  background-color: theme(background2);
  text-align: center;
  font-size: 1.2em;
  letter-spacing: 0.18em;
  height: 50px;
  vertical-align: middle;
  padding-top: 25px;
}
</style>
