
import { Form } from 'vee-validate';
import { defineComponent } from 'vue';
import { Inquiry, postInquiry } from '@/form/postInquiry';
import VTextArea from '@/components/form/VTextArea.vue';
import VTextInput from '@/components/form/VTextInput.vue';
import { inquirySchema } from '@/form/validationSchema';

export default defineComponent({
  name: 'ContactForm',
  components: {
    VTextArea,
    VTextInput,
    Form,
  },
  emits: ['show-policy', 'sent'],
  setup(props, { emit }) {
    const schema = inquirySchema;

    const onSubmit = async (values: Inquiry) => {
      try {
        await postInquiry(values, process.env.VUE_APP_MAIL);
        emit('sent', 'success');
      } catch (e) {
        emit('sent', 'failed');
      }
    };

    const showPrivacyPolicy = () => {
      emit('show-policy');
    };

    return {
      schema,
      showPrivacyPolicy,
      onSubmit,
    };
  },
});
