<template>
  <modal-window v-show="contactFormActive" @close-modal="toggleContactForm">
    <transition appear name="mode-fade" mode="out-in">
      <privacy-policy
        v-if="contactPolicyActive"
        style="height: 60vh"
        @close-policy="toggleCPolicy"
      />
      <v-response-message
        v-else-if="contactSent"
        message="お問いわせ"
        style="height: 60vh"
        :status="contactStatus"
      />
      <contact-form
        v-else
        @show-policy="toggleCPolicy"
        @sent="setContactSent"
      />
    </transition>
  </modal-window>

  <modal-window v-show="reserveFormActive" @close-modal="toggleReserveForm">
    <transition appear name="mode-fade" mode="out-in">
      <privacy-policy
        v-if="reservePolicyActive"
        class="reserve"
        @close-policy="toggleRPolicy"
      />
      <v-response-message
        v-else-if="reservationSent"
        message="ご予約"
        class="reserve"
        :status="reservationStatus"
      />
      <reserve-form
        v-else
        class="reserve"
        @show-policy="toggleRPolicy"
        @sent="setReservationSent"
      />
    </transition>
  </modal-window>

  <div class="contact-box">
    <div class="contact-box__item">
      <div class="contact-box__background--default" />
      <h3 class="contact-box__title">CONTACT</h3>

      <div class="contact-box__text">
        <p>
          ご予約やサービスメニューに関するご質問は、コンタクトフォームよりお問い合わせください。
        </p>
      </div>

      <div class="contact-box__button">
        <VButton class="contact-box__button" @click="toggleContactForm">
          お問合せフォーム →
        </VButton>
      </div>
    </div>

    <div class="contact-box__item">
      <div class="contact-box__background--white" />
      <h3 class="contact-box__title">RESERVE</h3>

      <div class="contact-box__text">
        <p>オンラインからご予約していただくことができます。</p>
      </div>

      <div class="contact-box__button">
        <VButton class="contact-box__button" @click="toggleReserveForm">
          RESERVE →
        </VButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import VButton from '@/components/buttons/VButton.vue';
import ContactForm from '@/components/contact/ContactForm.vue';
import PrivacyPolicy from '@/components/contact/PrivacyPolicy.vue';
import ModalWindow from '@/components/ModalWindow.vue';
import ReserveForm from '@/components/contact/ReserveForm.vue';
import VResponseMessage from '@/components/form/VResponseMessage.vue';

export default defineComponent({
  name: 'ContactBox',
  components: {
    VResponseMessage,
    ReserveForm,
    VButton,
    ModalWindow,
    PrivacyPolicy,
    ContactForm,
  },
  setup() {
    const contactFormActive = ref(false);
    const contactSent = ref(false);
    const contactStatus = ref('');

    const reserveFormActive = ref(false);
    const reservationSent = ref(false);
    const reservationStatus = ref('');

    const contactPolicyActive = ref(false);
    const reservePolicyActive = ref(false);

    const toggleCPolicy = () => {
      contactPolicyActive.value = !contactPolicyActive.value;
    };

    const toggleRPolicy = () => {
      reservePolicyActive.value = !reservePolicyActive.value;
    };

    const disableScroll = (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    const toggleContactForm = () => {
      contactFormActive.value = !contactFormActive.value;
    };

    const setContactSent = (res: string) => {
      contactSent.value = true;
      contactStatus.value = res;
    };

    const toggleReserveForm = () => {
      reserveFormActive.value = !reserveFormActive.value;
    };

    const setReservationSent = (res: string) => {
      reservationSent.value = true;
      reservationStatus.value = res;
    };

    return {
      contactFormActive,
      contactSent,
      contactStatus,
      reservationSent,
      reservationStatus,
      reserveFormActive,
      toggleContactForm,
      setContactSent,
      setReservationSent,
      toggleReserveForm,
      toggleRPolicy,
      toggleCPolicy,
      contactPolicyActive,
      reservePolicyActive,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/scss/global.scss';

.contact-box {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  @include respondTo(smallTablet) {
    flex-direction: column;
    &__item {
      margin: 0;
      left: 0;
      right: 0;
      width: 100% !important;
      padding: 20px !important;
    }

    &__background {
      &--default {
        width: 100% !important;
        height: 50% !important;
      }

      &--white {
        width: 100% !important;
        height: 50%;
        left: 0;
        top: 50%;
      }
    }
  }

  &__background {
    &--default {
      min-width: 50%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      backdrop-filter: blur(5px);
      opacity: 0.7;
      background-color: theme('background');
    }

    &--white {
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      backdrop-filter: blur(5px);
      opacity: 0.7;
      background-color: theme('background2');
    }
  }

  &__item {
    box-sizing: border-box;
    padding: 60px 80px;
    width: 50%;
  }

  &__title {
    letter-spacing: 0.4em;
    margin-bottom: 30px;
  }

  &__text {
    max-width: 100%;
    height: 60px;
  }

  &__button {
    margin-top: 20px;
  }
}

.mode-fade-enter-active,
.mode-fade-leave-active {
  transition: opacity 0.5s ease;
}

.mode-fade-enter-from,
.mode-fade-leave-to {
  opacity: 0;
}

.reserve {
  height: 80vh;

  @include respondTo(smallTablet) {
    height: 60vh;
  }
}
</style>
