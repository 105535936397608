import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimesCircle,
  faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTimesCircle, faCheckCircle);

export default FontAwesomeIcon;
