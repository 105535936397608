import axios from 'axios';
import { ContactForm7Response, isError } from './ContactForm7Response';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';

dayjs.locale(ja);

export type Reservation = {
  readonly name: string;
  readonly email: string;
  readonly message: string;
  readonly address: string;
  readonly course: string;
  readonly phone: string;
  readonly date: Date;
};

export const postReservation = async (
  reservation: Reservation,
  to: string,
): Promise<void> => {
  const day = dayjs(reservation.date);

  const body = new URLSearchParams();
  body.append('username', reservation.name);
  body.append('email', reservation.email);
  body.append('message', reservation.message);
  body.append('address', reservation.address);
  body.append('course', reservation.course);
  body.append('phone', reservation.phone);
  body.append('date', day.format('YYYY年M月D日').toString());
  body.append('time', day.format('H時m分').toString());

  const client = axios.create({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const response = await client.post<ContactForm7Response>(to, body);
  if (isError(response.data.status)) {
    throw new Error(response.data.message);
  } else if (response.status != 200) {
    throw new Error();
  }
};
