<template>
  <div class="input">
    <div class="input__head">
      <label :for="name">{{ label }}</label>
      <p v-if="isImportant" class="input__req">必須</p>
    </div>

    <div class="input__body">
      <textarea
        :id="name"
        :name="name"
        :value="value"
        @input="handleChange"
        @blur="handleBlur"
      />
      <div v-show="errorMessage" class="input__error">
        <strong>{{ errorMessage }}</strong>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useField } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isImportant: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { value, errorMessage, handleBlur, handleChange } = useField(
      props.name,
    );

    return {
      handleChange,
      handleBlur,
      errorMessage,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/scss/module/input.scss';

.input {
  @extend %base-input;
  height: 160px;

  @include respondTo(smallTablet) {
    height: 150px;
  }

  &__head {
    @extend %base-input__head;
  }

  &__req {
    @extend %base-input__req;
  }

  &__error {
    @extend %base-input__error;
  }

  &__body {
    @extend %base-input__body;
  }
}
</style>
