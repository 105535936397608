<template>
  <div class="video-container">
    <video playsinline autoplay loop muted>
      <source src="@/assets/background.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VideoBackground',
});
</script>

<style lang="scss" scoped>
video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}
</style>
