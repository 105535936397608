<template>
  <div class="contact">
    <h1 class="contact__title">CONTACT</h1>
    <Form :validation-schema="schema" @submit="onSubmit">
      <v-text-input name="name" label="お名前" />
      <v-text-input name="email" label="メールアドレス" />
      <v-text-area is-important name="message" label="ご用件" />
      <div>
        <div style="font-size: 1.4em; margin-top: 10px">
          お問い合わせいただく内容及び個人情報は、当社の
          <div
            style="cursor: pointer; display: inline; text-decoration: underline"
            @click="showPrivacyPolicy"
          >
            個人情報保護方針
          </div>
          に則り管理いたします。
        </div>
        <button class="contact__button" type="submit">SUBMIT →</button>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { Form } from 'vee-validate';
import { defineComponent } from 'vue';
import { Inquiry, postInquiry } from '@/form/postInquiry';
import VTextArea from '@/components/form/VTextArea.vue';
import VTextInput from '@/components/form/VTextInput.vue';
import { inquirySchema } from '@/form/validationSchema';

export default defineComponent({
  name: 'ContactForm',
  components: {
    VTextArea,
    VTextInput,
    Form,
  },
  emits: ['show-policy', 'sent'],
  setup(props, { emit }) {
    const schema = inquirySchema;

    const onSubmit = async (values: Inquiry) => {
      try {
        await postInquiry(values, process.env.VUE_APP_MAIL);
        emit('sent', 'success');
      } catch (e) {
        emit('sent', 'failed');
      }
    };

    const showPrivacyPolicy = () => {
      emit('show-policy');
    };

    return {
      schema,
      showPrivacyPolicy,
      onSubmit,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/scss/global.scss';

.contact {
  height: 50vh;

  &__title {
    margin: 20px auto 20px;
    text-align: center;
  }

  &__button {
    display: block;
    width: 100px;
    font-weight: bold;
    background: none;
    border: none;
    border-bottom: 2px solid black;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button:focus {
  outline: none;
}
</style>
