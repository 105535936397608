
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrivacyPolicy',
  emits: ['close-policy'],
  setup(props, { emit }) {
    const closeThis = () => {
      emit('close-policy');
    };

    return {
      closeThis,
    };
  },
});
