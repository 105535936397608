<template>
  <div class="concept">
    <div class="concept__cover">
      <figure class="concept__default">
        <img
          src="@/assets/concept.png"
          width="490"
          height="382"
          alt="concept"
        />
      </figure>

      <figure class="concept__mobile">
        <img
          src="@/assets/concept_mobile.png"
          width="300"
          height="133"
          alt="concept"
        />
      </figure>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Concept',
});
</script>

<style lang="scss" scoped>
@import '~@/scss/global';

.concept {
  margin-top: 160px;
  display: flex;
  flex-direction: row;

  &__cover {
    position: relative;
    z-index: 2;
    max-width: 40%;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__mobile {
    display: none;
  }

  &__background {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background: theme(background);
    backdrop-filter: blur(5px);
    opacity: 0.7;
  }

  &__content {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    margin: 50px 0 0 -50px;
    width: 70%;
    padding: 70px;
    color: #333;
  }

  @include respondTo(mobile) {
    margin-top: 100px !important;

    &__content {
      width: 100% !important;
      padding: 30px;
    }
  }

  @include respondTo(smallTablet) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__default {
      display: none;
    }
    &__mobile {
      display: block;
    }

    &__cover {
      max-width: 100%;
    }

    &__content {
      width: 100%;
      margin: -20px 0 0 0;
    }
  }
}
</style>
