<template>
  <div class="contact">
    <h1 class="contact__title">RESERVE</h1>
    <Form :validation-schema="schema" @submit="onSubmit">
      <v-text-input name="name" label="お名前" />
      <v-text-input name="address" label="住所" />
      <v-radio-button name="course" />
      <v-date-input />
      <v-text-input name="phone" label="電話番号" />
      <v-text-input name="email" label="メールアドレス" />
      <v-text-area name="message" label="その他（自由記入）" />

      <div style="font-size: 1.4em; margin-top: 10px">
        お問い合わせいただく内容及び個人情報は、当社の
        <div
          style="cursor: pointer; display: inline; text-decoration: underline"
          @click="showPrivacyPolicy"
        >
          個人情報保護方針
        </div>
        に則り管理いたします。
      </div>
      <button class="button" type="submit">SUBMIT →</button>
    </Form>
  </div>
</template>

<script lang="ts">
import { Form } from 'vee-validate';
import { defineComponent, ref } from 'vue';
import { postReservation, Reservation } from '@/form/postReservation';
import VTextArea from '@/components/form/VTextArea.vue';
import VTextInput from '@/components/form/VTextInput.vue';
import VDateInput from '@/components/form/VDateInput.vue';
import VRadioButton from '@/components/form/VRadioButton.vue';
import { reservationSchema } from '@/form/validationSchema';

export default defineComponent({
  name: 'ReserveForm',
  components: {
    Form,
    VRadioButton,
    VDateInput,
    VTextInput,
    VTextArea,
  },
  emits: ['show-policy', 'sent'],
  setup(props, { emit }) {
    const isRange = ref(false);

    const onSubmit = async (values: Reservation) => {
      try {
        await postReservation(values, process.env.VUE_APP_RESERVATION);
        emit('sent', 'success');
      } catch (e) {
        emit('sent', 'failed');
      }
    };

    const showPrivacyPolicy = () => {
      emit('show-policy');
    };

    const schema = reservationSchema;

    return {
      isRange,
      showPrivacyPolicy,
      onSubmit,
      schema,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/scss/global.scss';

.contact {
  &__title {
    margin: 20px auto 20px;
    text-align: center;
  }
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  height: 50px;
}

.button {
  display: block;
  width: 100px;
  font-weight: bold;
  background: none;
  border: none;
  border-bottom: 2px solid black;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
}

button:focus {
  outline: none;
}
</style>
