
import {TweenMax} from 'gsap';
import {defineComponent, onMounted} from 'vue';

export default defineComponent({
  name: 'VCursor',
  /**
   *
   */
  setup() {

    onMounted(() => {
      // Move the cursor
      const bigBall = document.querySelector('.cursor__ball--big');
      const smallBall = document.querySelector('.cursor__ball--small');
      const hoverables = document.querySelectorAll('.hoverable');

      document.body.addEventListener('mousemove', (e: MouseEvent) => {
        TweenMax.to(bigBall, 0.4, {
          x: e.pageX - 15,
          y: e.pageY - 15,
        });
        TweenMax.to(smallBall, 0.1, {
          x: e.pageX - 7,
          y: e.pageY - 7,
        });
      });

      for (const element of hoverables) {
        element.addEventListener('mouseenter', () => {
          TweenMax.to(bigBall, 0.3, {
            scale: 4,
          });
        });
        element.addEventListener('mouseleave', () => {
          TweenMax.to(bigBall, 0.3, {
            scale: 1,
          });
        });
      }
    });
  },
});
