<template>
  <div class="input">
    <div class="input__head">
      <label>日時</label>
      <p class="input__req">必須</p>
    </div>

    <div class="input__body">
      <div class="input__item">
        <label for="date">日付</label>
        <date-picker
          v-model="value"
          mode="date"
          color="pink"
          :model-value="value"
        >
          <template #default="{ inputValue, inputEvents }">
            <input id="date" :value="inputValue" v-on="inputEvents" />
          </template>
        </date-picker>
      </div>

      <div class="input__item">
        <label for="time">時間</label>
        <date-picker
          v-model="value"
          mode="time"
          color="pink"
          :model-value="value"
          :minute-increment="30"
          is24hr
        >
          <template #default="{ inputValue, inputEvents }">
            <input id="time" :value="inputValue" v-on="inputEvents" />
          </template>
        </date-picker>
      </div>

      <div v-show="errorMessage" class="input__error">
        <strong>{{ errorMessage }}</strong>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'VDateInput',
  components: {},
  emits: ['show-policy'],
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { value, errorMessage, handleBlur, handleChange } = useField(
      'date',
      undefined,
      {
        validateOnValueUpdate: true,
      },
    );

    return {
      handleChange,
      handleBlur,
      errorMessage,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/scss/global.scss';
@import '~@/scss/module/input.scss';

.input {
  height: 60px;

  @include respondTo(smallTablet) {
    height: 90px;
  }

  @extend %base-input;

  &__head {
    @extend %base-input__head;
  }

  &__req {
    @extend %base-input__req;
  }

  &__error {
    @extend %base-input__error;
  }

  &__body {
    width: 70%;
    padding: 0 15px;

    strong {
      font-size: small;
    }

    input {
      font-size: medium;
      resize: none;
      box-sizing: border-box;
      width: 120px;
      background: #f5f5f5;
      border: none;
      outline: none;
    }

    input {
      min-height: 30px;
      padding-left: 15px;
    }

    input::placeholder {
      border-left: solid 1px rgba(128, 128, 128, 0.5);
      padding-left: 10px;
    }

    @include respondTo(smallTablet) {
      width: 100%;
      margin-top: 20px;
      padding: 0;
    }
  }

  &__item {
    width: 200px;
    display: inline-flex;
    flex-direction: row;

    label {
      margin-right: 20px;
    }

    @include respondTo(mobile) {
      width: 160px;
      label {
        margin-right: 5px;
      }
    }
  }
}
</style>
