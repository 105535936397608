
import { defineComponent } from 'vue';
import Access from '@/components/Access.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/header/AppHeader.vue';
import Concept from '@/components/Concept.vue';
import ContactBox from '@/components/contact/ContactBox.vue';
import Price from '@/components/Price.vue';
import VCursor from '@/components/VCursor.vue';
import VideoBackground from '@/components/VideoBackground.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Access,
    VCursor,
    Concept,
    VideoBackground,
    AppHeader,
    Price,
    AppFooter,
    ContactBox,
  },
});
