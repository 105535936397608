
import { defineComponent } from 'vue';
import CloseButton from '@/components/buttons/CloseButton.vue';

export default defineComponent({
  name: 'Concept',
  components: {
    CloseButton,
  },
  emits: ['close-modal'],
  methods: {
    closeMenu() {
      this.$emit('close-modal');
    },
  },
});
