<template>
  <div class="modal">
    <div class="modal__bg" />
    <div style="display: flex; flex-direction: row-reverse">
      <CloseButton class="modal__close-button" @close="closeMenu" />

      <div class="modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CloseButton from '@/components/buttons/CloseButton.vue';

export default defineComponent({
  name: 'Concept',
  components: {
    CloseButton,
  },
  emits: ['close-modal'],
  methods: {
    closeMenu() {
      this.$emit('close-modal');
    },
  },
});
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    backdrop-filter: blur(5px);
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  &__close-button {
    position: absolute;
    margin: 5px;
    z-index: 1000;
  }

  &__content {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 20px 30px 30px 30px;
    width: 90vw;
    max-width: 900px;
    max-height: 80vh;
    overflow-y: scroll;
    position: relative;
    z-index: 101;
    background: white;
    border-radius: 5px;

    @media screen and (max-width: 650px) {
      padding: 25px 10px;
    }
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}

.mode-fade-enter-active,
.mode-fade-leave-active {
  transition: opacity 0.5s ease;
}

.mode-fade-enter-from,
.mode-fade-leave-to {
  opacity: 0;
}
</style>
