
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'VResponseMessage',
  props: {
    status: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isSuccess = ref(false);

    onMounted(() => {
      isSuccess.value = props.status === 'success';
    });
    return { isSuccess };
  },
});
