<template>
  <div class="input">
    <div class="input__head">
      <label>コース</label>
      <p class="input__req">必須</p>
    </div>
    <div class="input__body">
      <div style="display: inline-block">
        <input id="one" v-model="value" type="radio" value="'まつエク'" />
        <label for="one">まつエク</label>
        <input id="two" v-model="value" type="radio" value="'リペア" />
        <label for="two">リペア</label>
      </div>
      <div v-show="errorMessage" class="input__error">
        <strong>{{ errorMessage }}</strong>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'VRadioButton',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { value, errorMessage, handleBlur, handleChange } = useField(
      props.name,
      undefined,
      {
        validateOnValueUpdate: true,
      },
    );
    return {
      handleChange,
      handleBlur,
      errorMessage,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/scss/global.scss';
@import '~@/scss/module/input.scss';

.input {
  height: 60px;

  @include respondTo(smallTablet) {
    height: 90px;
  }

  @extend %base-input;

  &__head {
    @extend %base-input__head;
  }

  &__req {
    @extend %base-input__req;
  }

  &__error {
    @extend %base-input__error;
  }

  &__body {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    letter-spacing: 2px;
    vertical-align: middle;

    label {
      line-height: 35px;
    }

    strong {
      font-size: small;
    }

    input {
      resize: none;
      box-sizing: border-box;
      margin-right: 10px;
      border: none;
      outline: none;
    }

    input {
      height: 30px;
    }

    input::placeholder {
      border-left: solid 1px rgba(128, 128, 128, 0.5);
      padding-left: 10px;
    }

    @include respondTo(smallTablet) {
      padding: 0;
    }
  }
}
</style>
