<template>
  <div class="price">
    <div class="price__background" />
    <VTitle>MENU&PRICE</VTitle>
    <div class="price__content">
      <div class="price__item">
        <h3 class="price__title">Eyelash Extension</h3>
        <table class="price__table">
          <tbody>
            <tr>
              <th>まつエク</th>
              <td>¥5,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="price__item">
        <h3 class="price__title">Repair</h3>
        <table class="price__table">
          <tbody>
            <tr>
              <th>リペア</th>
              <td>¥3,000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VTitle from '@/components/VTitle.vue';

export default defineComponent({
  name: 'Price',
  components: {
    VTitle,
  },
});
</script>

<style lang="scss" scoped>
@import '~@/scss/global.scss';

.price {
  padding: 80px;
  position: relative;

  &__background {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background: theme(background);
    backdrop-filter: blur(5px);
    opacity: 0.7;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__item {
    margin: 40px 25px 0 25px;
    width: 40%;
  }

  &__title {
    letter-spacing: 0.4em;
    margin-bottom: 40px;
  }

  &__table {
    letter-spacing: 0.15em;
    text-align: left;
    vertical-align: top;
    font-size: 1.4em;

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    th {
      font-weight: normal;
      padding-right: 40px;
    }
  }

  @include respondTo(laptop) {
    padding: 80px 20px 80px 20px;
  }

  @include respondTo(mobile) {
    padding: 80px 20px 80px 20px;
    margin: 0;
  }

  @include respondTo(smallTablet) {
    &__content {
      flex-direction: column;
    }

    &__item {
      margin: 40px 25px 0 25px;
      width: 100%;
    }
  }
}
</style>
