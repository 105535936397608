
import { Form } from 'vee-validate';
import { defineComponent, ref } from 'vue';
import { postReservation, Reservation } from '@/form/postReservation';
import VTextArea from '@/components/form/VTextArea.vue';
import VTextInput from '@/components/form/VTextInput.vue';
import VDateInput from '@/components/form/VDateInput.vue';
import VRadioButton from '@/components/form/VRadioButton.vue';
import { reservationSchema } from '@/form/validationSchema';

export default defineComponent({
  name: 'ReserveForm',
  components: {
    Form,
    VRadioButton,
    VDateInput,
    VTextInput,
    VTextArea,
  },
  emits: ['show-policy', 'sent'],
  setup(props, { emit }) {
    const isRange = ref(false);

    const onSubmit = async (values: Reservation) => {
      try {
        await postReservation(values, process.env.VUE_APP_RESERVATION);
        emit('sent', 'success');
      } catch (e) {
        emit('sent', 'failed');
      }
    };

    const showPrivacyPolicy = () => {
      emit('show-policy');
    };

    const schema = reservationSchema;

    return {
      isRange,
      showPrivacyPolicy,
      onSubmit,
      schema,
    };
  },
});
