<template>
  <div class="header">
    <h1 class="header__logo">
      <img src="@/assets/logo.png" alt="sofa-hair" />
    </h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppHeader',
});
</script>

<style lang="scss" scoped>
@import '~@/scss/global';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  box-sizing: border-box;
  width: 100vw;
  padding: 0 4%;
  position: fixed;
  top: 0;
  z-index: 100;

  &__logo {
    width: 280px;
    z-index: 201;
    @include respondTo(mobile) {
      width: 120px;
    }
  }

  @include respondTo(mobile) {
    height: 80px;
  }
}
</style>
