
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'VDateInput',
  components: {},
  emits: ['show-policy'],
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { value, errorMessage, handleBlur, handleChange } = useField(
      'date',
      undefined,
      {
        validateOnValueUpdate: true,
      },
    );

    return {
      handleChange,
      handleBlur,
      errorMessage,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value,
    };
  },
});
