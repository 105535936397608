<template>
  <h1>
    <slot />
  </h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VTitle',
});
</script>

<style lang="scss" scoped>
h1 {
  background: url(~@/assets/bg_h1.gif) no-repeat center bottom;
  font-weight: 600;
  letter-spacing: 0.3em;
  padding: 0 0 25px 0;
  margin: 0 auto 50px auto;
  text-align: center;
}
</style>
