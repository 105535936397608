
import { defineComponent, ref } from 'vue';
import VButton from '@/components/buttons/VButton.vue';
import ContactForm from '@/components/contact/ContactForm.vue';
import PrivacyPolicy from '@/components/contact/PrivacyPolicy.vue';
import ModalWindow from '@/components/ModalWindow.vue';
import ReserveForm from '@/components/contact/ReserveForm.vue';
import VResponseMessage from '@/components/form/VResponseMessage.vue';

export default defineComponent({
  name: 'ContactBox',
  components: {
    VResponseMessage,
    ReserveForm,
    VButton,
    ModalWindow,
    PrivacyPolicy,
    ContactForm,
  },
  setup() {
    const contactFormActive = ref(false);
    const contactSent = ref(false);
    const contactStatus = ref('');

    const reserveFormActive = ref(false);
    const reservationSent = ref(false);
    const reservationStatus = ref('');

    const contactPolicyActive = ref(false);
    const reservePolicyActive = ref(false);

    const toggleCPolicy = () => {
      contactPolicyActive.value = !contactPolicyActive.value;
    };

    const toggleRPolicy = () => {
      reservePolicyActive.value = !reservePolicyActive.value;
    };

    const disableScroll = (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    const toggleContactForm = () => {
      contactFormActive.value = !contactFormActive.value;
    };

    const setContactSent = (res: string) => {
      contactSent.value = true;
      contactStatus.value = res;
    };

    const toggleReserveForm = () => {
      reserveFormActive.value = !reserveFormActive.value;
    };

    const setReservationSent = (res: string) => {
      reservationSent.value = true;
      reservationStatus.value = res;
    };

    return {
      contactFormActive,
      contactSent,
      contactStatus,
      reservationSent,
      reservationStatus,
      reserveFormActive,
      toggleContactForm,
      setContactSent,
      setReservationSent,
      toggleReserveForm,
      toggleRPolicy,
      toggleCPolicy,
      contactPolicyActive,
      reservePolicyActive,
    };
  },
});
