<template>
  <div class="access">
    <div class="access__background" />
    <VTitle>ACCESS</VTitle>
    <div class="access__content">
      <div class="access__item">
        <h3 class="access__title">完全予約制</h3>
        <table class="access__table">
          <tbody>
            <tr>
              <th>住所:</th>
              <td>
                〒650-0023
                <br />
                神戸市中央区栄町通1丁目1番9号 東方ビルディング201号室
                <br />
                <a href="http://goo.gl/maps/dj07u" class="hoverable">
                  Google Map
                </a>
              </td>
            </tr>
            <tr>
              <th>TEL&FAX:</th>
              <td>078-393-5446</td>
            </tr>
            <tr>
              <th>営業日時:</th>
              <td>
                火〜土11:00〜20:00
                <br />
                日11:00〜19:00
              </td>
            </tr>
            <tr>
              <th>定休日：</th>
              <td>毎月曜・第三火曜</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VTitle from '@/components/VTitle.vue';

export default defineComponent({
  name: 'Price',
  components: {
    VTitle,
  },
});
</script>

<style lang="scss" scoped>
@import '~@/scss/global.scss';

.access {
  padding: 80px;
  margin-top: 130px;
  position: relative;

  &__background {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background: theme(background);
    backdrop-filter: blur(5px);
    opacity: 0.7;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__item {
    margin: 40px 25px 0 25px;
    width: 70%;
  }

  &__title {
    letter-spacing: 0.4em;
    margin-bottom: 40px;
  }

  &__table {
    letter-spacing: 0.15em;
    text-align: left;
    vertical-align: top;
    font-size: 1.4em;

    a {
      color: black;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    tbody {
      display: table-row-group;

      border-color: inherit;
    }

    th {
      font-weight: normal;
      padding-right: 40px;
      vertical-align: top;
    }
  }

  @include respondTo(laptop) {
    padding: 80px 20px 80px 20px;
  }

  @include respondTo(mobile) {
    padding: 80px 20px 80px 20px;
    margin: 0;
  }

  @include respondTo(smallTablet) {
    &__content {
      flex-direction: column;
    }

    &__item {
      margin: 0;
      width: 100%;
    }
  }
}
</style>
