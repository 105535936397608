<template>
  <div class="v-button hoverable">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VButton',
});
</script>

<style lang="scss" scoped>
button {
  background: none;
  border: none;
}

.v-button {
  display: inline;
  font-size: 1.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid #000;
  font-weight: bold;
}
</style>
