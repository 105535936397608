import axios from 'axios';
import { ContactForm7Response, isError } from './ContactForm7Response';

export type Inquiry = {
  readonly name: string;
  readonly email: string;
  readonly message: string;
};

export const postInquiry = async (
  inquiry: Inquiry,
  to: string,
): Promise<void> => {
  const body = new URLSearchParams();
  body.append('username', inquiry.name);
  body.append('email', inquiry.email);
  body.append('message', inquiry.message);

  const client = axios.create({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const response = await client.post<ContactForm7Response>(to, body);
  if (isError(response.data.status)) {
    throw new Error(response.data.message);
  } else if (response.status != 200) {
    throw new Error();
  }
};
