export type ContactForm7Response = {
  readonly into: string;
  readonly status: ContactForm7Status;
  readonly message: string;
};

const ContactForm7Statuses = {
  sent: 'mail_sent',
  failed: 'mail_failed',
  validationFailed: 'validation_failed',
  spam: 'spam',
} as const;

export type ContactForm7Status = typeof ContactForm7Statuses[keyof typeof ContactForm7Statuses];

export const isError = (status: ContactForm7Status): boolean => {
  switch (status) {
    case ContactForm7Statuses.sent:
      return false;
    case ContactForm7Statuses.failed:
    case ContactForm7Statuses.validationFailed:
    case ContactForm7Statuses.spam:
      return true;
  }
};
