<template>
  <VCursor />
  <VideoBackground />
  <AppHeader />
  <div class="content-wrapper">
    <div class="content-wrapper__item">
      <Concept />
    </div>

    <div class="content-wrapper__item">
      <Price />
    </div>

    <div class="content-wrapper__item">
      <ContactBox />
    </div>

    <div class="content-wrapper__item">
      <Access />
    </div>
  </div>
  <AppFooter />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Access from '@/components/Access.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/header/AppHeader.vue';
import Concept from '@/components/Concept.vue';
import ContactBox from '@/components/contact/ContactBox.vue';
import Price from '@/components/Price.vue';
import VCursor from '@/components/VCursor.vue';
import VideoBackground from '@/components/VideoBackground.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Access,
    VCursor,
    Concept,
    VideoBackground,
    AppHeader,
    Price,
    AppFooter,
    ContactBox,
  },
});
</script>

<style lang="scss">
body {
  cursor: none;
}
</style>
<style lang="scss" scoped>
@import '~@/scss/global.scss';

.content-wrapper {
  max-width: 1200px;
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;

  @include respondTo(mobile) {
    padding-right: 10px;
    padding-left: 10px;
  }

  &__item {
    width: 100%;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
</style>
