<template>
  <div>
    <div @click="closeThis">
      <span style="font-size: 50px">⭠</span>
    </div>
    <h1 class="policy__title">Privacy Policy</h1>
    <div class="policy__content">
      <ol>
        <li>個人情報の保護に関し、法令及び国が定める指針を遵守します。</li>
        <li>
          個人情報の取得・利用に当たっては、その利用目的を特定することとし、特定された目的意外には利用いたしません。また、利用目的の達成に必要な範囲を超えて個人情報を取り扱うことはいたしません。
        </li>
        <li>
          取得した個人情報は、法令に定める場合等を除き、第三者に提供することもいたしません。
        </li>
        <li>
          要配慮個人情報は、本人に同意なく取得することも、第三者に提供することもいたしません。
        </li>
        <li>
          法令の定める場合等に個人情報を第三者に提供する場合には、法令等の要件に従って、必要な記録を作成し、当該記録を保存いたします。
        </li>
        <li>
          個人情報の漏洩、滅失又は毀損を防止し、及び是正するために、個人情報を適切に管理することとし、組織的・人的・技術的な安全対策措置を講じます。
        </li>
        <li>
          個人情報の取り扱いに関する苦情を受けた場合は、適切に対応いたします。
        </li>
        <li>個人情報の取り扱いについては、継続的に改善して参ります。</li>
      </ol>
      <div class="policy__caption">
        2020年8月23日
        <br />
        Sofa hair
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrivacyPolicy',
  emits: ['close-policy'],
  setup(props, { emit }) {
    const closeThis = () => {
      emit('close-policy');
    };

    return {
      closeThis,
    };
  },
});
</script>

<style lang="scss" scoped>
.policy {
  &__title {
    text-align: center;
    margin-bottom: 10px;
  }

  &__content {
    font-size: 1.8em;
    line-height: 2em;
    max-height: 80vh;
    overflow: scroll;

    ol {
      list-style-position: inside;
    }
  }

  &__caption {
    font-weight: bold;
  }
}
</style>
