
import { defineComponent } from 'vue';
import VTitle from '@/components/VTitle.vue';

export default defineComponent({
  name: 'Price',
  components: {
    VTitle,
  },
});
