import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import FontAwesomeIcon from '@/plugins/fontawesome';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router, SetupCalendar)
  // Use the components
  .component('Calendar', Calendar)
  .component('DatePicker', DatePicker)
  .mount('#app');
