
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'VRadioButton',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { value, errorMessage, handleBlur, handleChange } = useField(
      props.name,
      undefined,
      {
        validateOnValueUpdate: true,
      },
    );
    return {
      handleChange,
      handleBlur,
      errorMessage,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value,
    };
  },
});
