<template>
  <div class="input">
    <div class="input__head">
      <label :for="name">{{ label }}</label>
      <p class="input__req">必須</p>
    </div>

    <div class="input__body">
      <input
        :id="name"
        :name="name"
        type="text"
        :value="value"
        @input="handleChange"
        @blur="handleBlur"
      />
      <strong v-show="errorMessage" class="input__error">
        {{ errorMessage }}
      </strong>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name);

    return {
      handleChange,
      handleBlur,
      errorMessage,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value,
      meta,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/scss/module/input.scss';

.input {
  height: 60px;

  @include respondTo(smallTablet) {
    height: 90px;
  }

  @extend %base-input;

  &__head {
    @extend %base-input__head;
  }

  &__req {
    @extend %base-input__req;
  }

  &__error {
    @extend %base-input__error;
  }

  &__body {
    @extend %base-input__body;
  }
}
</style>
