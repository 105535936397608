import { object as yupObject, string as yupString, date as yupDate } from 'yup';

export const inquirySchema = yupObject().shape({
  name: yupString().required('お名前を入力してください'),
  email: yupString()
    .email('無効なメールアドレスです')
    .required('メールアドレスを入力してください'),
  message: yupString().required('ご用件を入力してください'),
});

const phoneRegExp = /^(\d{10}|\d{11})$/;

export const reservationSchema = yupObject().shape({
  name: yupString().required('お名前を入力してください'),
  email: yupString()
    .email('無効なメールアドレスです')
    .required('メールアドレスを入力してください'),
  phone: yupString()
    .matches(phoneRegExp, 'ハイフンなしの10(11)桁の数字を入力してください')
    .required('電話番号入力してください'),
  address: yupString().required('ご住所を入力してください'),
  course: yupString().required('コースを選んでください'),
  date: yupDate().required('日時を選択してください'),
});
