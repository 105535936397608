<template>
  <button role="button" class="close-button" @click="$emit('close')">
    <span />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CloseButton',
  emits: ['close'],
});
</script>

<style lang="scss" scoped>
.close-button {
  z-index: 1000;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  width: 40px;
  height: 40px;
  transition: transform 600ms;

  span,
  span:before,
  span:after {
    background: black;
    content: '';
    cursor: pointer;
    display: block;
    height: 2px;
    position: absolute;
    width: 20px;
  }

  span:first-child {
    background: none;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  span:before {
    transform: rotate(45deg);
  }

  span:after {
    transform: translateY(-2px) rotate(-45deg);
    top: 2px;
  }

  &:hover {
    transform: rotate(360deg) scale(1.1);
    transition: transform 600ms;
  }
}
</style>
